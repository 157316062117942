
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react"
import { useTranslation } from "react-i18next"
import DefaultLayout from "../../layout";

import * as styles from "../index.module.scss"

const PrivacyPolicyPage = (props) => {
  const file = props.data.file;

  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("privacyPolicy")}>
      <div className={styles.layoutBox}>
        <h1>{t("privacyPolicy")}</h1>

        <iframe
        style={{border: 0, width: "100%", height: "200px"}}
        src="https://measure.beambot.top/index.php?module=CoreAdminHome&action=optOut&language=de&fontColor=ffffff&fontSize=&fontFamily=Roboto%2C%20sans-serif"
        title="Matomo Analytics Opt-Out"
        ></iframe>

        <MDXRenderer >{file.childMdx.body}</MDXRenderer>
      </div>
    </DefaultLayout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(
      sourceInstanceName: {eq: "contentPages"}, relativeDirectory: {eq: "legal/privacyPolicy"}, name: {eq: $language}
    ) {
      id
      childMdx {
        body
      }
      name
    }
  }
`;